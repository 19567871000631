import {
  LogLevel,
  getLogger,
  setLogHandler,
  setLogLevel,
} from '~/modules/logging';
import * as loggerPlugin from '~/plugins/logger';

export function ClientJS() {
  const logger = getLogger();
  setLogHandler(loggerPlugin.createLogger());
  setLogLevel(LogLevel.INFO);

  logger.warn('BOOTSTRAP -----------------');
  return <></>;
}
