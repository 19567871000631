import type { LogLevel } from '~/modules/logging';
import { ConsoleLogHandler } from '~/modules/logging';

type ConsoleLogHandlerConfig = {
  logLevel?: LogLevel | string;
  logToConsole?: boolean;
  prefix?: string;
};

export class NoOpLogger {
  log(): void {}
}

export function createLogger(
  opts?: ConsoleLogHandlerConfig,
): ConsoleLogHandler {
  return new ConsoleLogHandler(opts);
}

export function createNoOpLogger(): NoOpLogger {
  return new NoOpLogger();
}
